.slide{
  width: calc(50% - 8px);
  padding: 0 8px;
}

@media(min-width: 640px){
  .slide{
    width: 320px;
    padding: 0 16px;
  }
}

@media (min-width: 768px) {
  .slide{
    width: 380px;
  }
}

@media (min-width: 1024px) {
  .slide{
    width: 340px;
  }
}

@media (min-width: 1280px) {
  .slide{
    width: 320px;
  }
}

@media (min-width: 1536px) {
  .slide{
    width: 382px;
  }
}