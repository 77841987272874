.arrow {
  width: 40px;
  height: 40px;
  fill: #fff;
  cursor: pointer;
  border-radius: 50%;
  padding: 14px;
  display: block;
}

@media (min-width: 1024px) {
  .arrow {
    width: 50px;
    height: 50px;
    padding: 17px;
  }
}