.events{
  position: relative;
}

.events::before{
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url('/bg-waves.jpg');
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
