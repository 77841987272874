.box{
  background-image: url('/bg-weather.jpg');
  background-size: cover;
  position: relative;
}

.box::after{
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}